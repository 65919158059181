<template>
  <div
    :class="
      $vuetify.breakpoint.mdAndUp ? 'lower-margin' : 'lower-margin-mobile'
    "
  >
    <v-layout
      id="inicio"
      row
    >
      <v-card
        tile
        flat
        color="black"
        :style="
          mdUp
            ? 'width: 100%; min-height: 100vh;z-index:100 !important'
            : 'width: 100%;max-height: 150vh;'
        "
      >
      <div class="videoWrapper" style="">
    <!-- Copy & Pasted from YouTube -->
    <iframe width="560" height="349" src="https://player.vimeo.com/video/480537122?autoplay=1&loop=1&autopause=0&muted=1" allow="autoplay;" allowautoplay frameborder="0" allowfullscreen ></iframe>
</div>
      </v-card>
    </v-layout>

    <v-layout
      v-if="offsetTop < 100 && this.$vuetify.breakpoint.smAndUp"
      :style="
        language == 0
          ? 'z-index: 1000; position: fixed; bottom: 20px; float: bottom; left: 50%'
          : 'z-index: 1000; position: fixed; bottom: 20px; float: bottom; left: 48%'
      "
      row
      justify-center
    >
      <div class="chevron"></div>
      <div class="chevron"></div>
      <div class="chevron"></div>
      <span style="color: white; margin-top: 60px">
        {{ language == 0 ? "Desliza" : "Scroll Down" }}
      </span>
    </v-layout>
  </div>
</template>

<script>
import page_texts from "../../views/guests/guestTexts";

export default {
  data: () => ({
    previousIcon: "/static/keyboard_backspace-backward.svg",
    nexIcon: "/static/keyboard_backspace-frontward.svg",
    bannerimages: [],
    posicion: 0,
    items: [
      "/static/splash_small.png",
      "/static/splash_small.png",
      "/static/splash_small.png",
    ],
    galleryImages: [],
    iPosition: 0,
  }),
  methods: {
    getText() {
      var axios = require("axios");

      let that = this;

      var config = {
        method: "get",
        url:
          "http://ec2-107-23-108-138.compute-1.amazonaws.com/api/information/",
        headers: {},
      };

      axios(config)
        .then(function (response) {})
        .catch(function (error) {});
    },
    siguiente() {
      if (this.iPosition == 2) {
        this.iPosition = 0;
      } else {
        this.iPosition += 1;
      }
    },
    anterior() {
      if (this.iPosition == 0) {
        this.iPosition = 2;
      } else {
        this.iPosition -= 1;
      }
    },
  },
  computed: {
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    offsetTop() {
      return this.$store.state.offsetTop;
    },
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
    texts() {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish : page_texts.english;
    },
    namespace() {
      return this.$route.name;
    },
  },
  mounted() {
    this.getText();
  },
};
</script>
<style>

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 1, 4);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: white;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
@keyframes pulse {
  to {
    opacity: 1;
  }
}

.hero-container-sm {
  margin-top: 20%;
}
h3,
p {
  font-family: "Montserrat", sans-serif;
}
.lower-margin {
  margin-bottom: 10vh;
}
.lower-margin-mobile {
  margin-bottom: 50px;
}
.esteto-md {
  position: absolute;
  top: 20vh;
  right: 0;
  max-width: 100vw;
}
.v-carousel__controls {
  background: transparent;
  z-index: 3;
}
.v-carousel__controls__item .v-icon {
  font-size: 10px !important;
}
.hero-container-md {
  position: relative !important;
  top: 15% !important;
  width: 40%;
  margin-left: 50%;
  position: relative;
  top: 2vw;
}
.h3-md {
  font-size: 35px !important;
  width: 50% !important;
  margin-left: 30% !important;
  padding: 0 !important;
}
.h3-sm {
  width: 100% !important;
  margin-left: 0% !important;
}

.p-md {
  width: 60% !important;
  padding: 0 !important;
  margin-left: 30% !important;
}
.p-sm {
  width: 90% !important;
  margin-left: 10% !important;
}
.v-carousel__item {
  height: 100% !important;
}
.conoce-mas {
  float: right;
  color: white !important;
  background-color: #56984d !important;
  border-radius: 25px;
  text-transform: none !important;
  font-size: 12px !important;
}
.conoce-mas-btn {
  text-transform: none;
  text-decoration: none;
  color: white !important;
  background-color: transparent !important;
  font-size: 10px !important;
}
.signup-btn {
  text-decoration: none;

  color: white !important;
}
.button-md {
  min-width: 150px !important;
  width: 30%;
  height: 40px !important;
}

.button-sm {
  width: 30%;
  height: 40px !important;
}
.otorgar {
  text-transform: none;
  background-color: #a533ef !important;
  color: white !important;
  font-size: 10px !important;
  white-space: normal !important;
}
.hero-logo {
  max-width: 150px;
}
</style>
